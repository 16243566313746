/*
 * 初始化发起需求表格表头
 */
export function initialProductTable () {
  const columns = [
    {
      key: 'categoryId',
      renderHeader (h) {
        return h('div', [
          h(
            'span',
            {
              style: {
                color: 'red',
              },
            },
            '*',
          ),
          h('span', null, '商品分类'),
        ])
      },
      slot: 'categoryId',
      width: 150,
    },
    {
      key: 'productName',
      renderHeader (h) {
        return h('div', [
          h(
            'span',
            {
              style: {
                color: 'red',
              },
            },
            '*',
          ),
          h('span', null, '品名'),
        ])
      },
      slot: 'productName',
      width: 150,
    },
    {
      title: '规格',
      key: 'formate',
      renderHeader (h) {
        return h('div', [
          h(
            'span',
            {
              style: {
                color: 'red',
              },
            },
            '*',
          ),
          h('span', null, '规格'),
        ])
      },
      slot: 'formate',
      width: 150,
    },
    {
      title: '材质',
      key: 'material',
      renderHeader (h) {
        return h('div', [
          h(
            'span',
            {
              style: {
                color: 'red',
              },
            },
            '*',
          ),
          h('span', null, '材质'),
        ])
      },
      slot: 'material',
      width: 150,
    },
    {
      title: '重量/数量',
      key: 'count',
      slot: 'count',
      width: 200,
    },
    {
      title: '单价',
      key: 'price',
      slot: 'price',
      width: 180,
    },
    {
      title: '总金额(元)',
      key: 'totalPrice',
      slot: 'totalPrice',
      width: 150,
    },
    {
      title: '保证金比例(%)',
      key: 'depositValue',
      slot: 'depositValue',
      width: 140,
    },
    // {
    //   title: '操作',
    //   slot: 'action',
    //   width: 80,
    //   align: 'center',
    //   fixed: 'right',
    // },
  ]
  return columns
}

/*
 * 初始化求购列表表格表头
 */

export function initialNeedsListTable () {
  const columns = [
    {
      key: 'createDate',
      title: '发布时间',
      width: 200,
    },
    {
      key: 'productName',
      title: '商品名称',
    },
    {
      title: '规格/型号',
      key: 'formate',
    },
    {
      title: '采购价',
      key: 'price',
      slot: 'price',
      width: 120,
    },
    {
      title: '状态',
      key: 'status',
      width: 120,
      slot: 'status',
    },
    {
      title: '采购量',
      key: 'count',
      width: 120,
    },
    {
      title: '发布企业',
      key: 'shortName',
    },
    {
      title: '操作',
      slot: 'action',
      width: 180,
    },
  ]
  return columns
}
