<template>
  <Row justify="start" style="width: 400px;" class="default-sort">
      <Col span="5">
        <div>默认排序</div>
      </Col>
      <Col span="10">
        <span :class="{'sort-text': true, 'is-active': nowType === type.byNew}" class="border-right" @click="sortChange(type.byNew)">最新</span>
        <span :class="{'sort-text': true, 'is-active': nowType === type.byPrice}" class="border-right"  @click="sortChange(type.byPrice)">价格</span>
        <span :class="{'sort-text': true, 'is-active': nowType === type.byNumber}"  @click="sortChange(type.byNumber)">数量</span>
      </Col>
    </Row>
</template>
<script>
export default {
  name: 'NeedListSort',
  data () {
    return {
      isActive: false,
      type: {
        byNew: 'create_date',
        byPrice: 'price',
        byNumber: 'count'
      },
      nowType: 'create_date'
    }
  },
  methods: {
    sortChange (val) {
      this.nowType = val
      this.$emit('sortChange', val)
    }
  }
}
</script>

<style lang="less">
.default-sort {
  margin-bottom: 20px;
  .sort-text {
    cursor: pointer;
    color: #acacac;
    display: inline-block;
    padding: 0 8px;
    position: relative;
  }
  .is-active {
    color: #d12b23;
  }
  .sort-text:hover {
    color: #d12b23;
  }
  .border-left {
    border-left: 1px solid #acacac;
  }
  .border-right::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 16px;
    top: 2px;
    right: 0;
    background-color: #acacac;
  }
}

</style>
