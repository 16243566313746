<template>
  <Modal v-model="localShowModal" title="报价留言" class="quete-modal" width="600" @on-cancel="cancel">
    <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="100" :label-position="'left'">
      <Row class="form-row">
        <Col span="12">
        <FormItem label="详细地址" prop="address">
          <Input v-model="formValidate.address" placeholder="请输入详细地址" class="ivu-input-fix"></Input>
        </FormItem>
        </Col>
        <Col span="12">
        <FormItem label="供应商单位" prop="companyName">
          <Input v-model="formValidate.companyName" placeholder="请输入供应商单位" class="ivu-input-fix"></Input>
        </FormItem>
        </Col>
      </Row>
      <Row class="form-row">
        <Col span="12">
        <FormItem label="报价" prop="price">
          <InputNumber v-model="formValidate.price" :min="0" placeholder="请输入报价" class="ivu-input-fix" style="width: 95%;"></InputNumber>
        </FormItem>
        </Col>
        <Col span="12">
        <FormItem label="手机号码" prop="phone">
          <Input v-model="formValidate.phone" placeholder="请输入手机号码" class="ivu-input-fix"></Input>
        </FormItem>
        </Col>
      </Row>
      <Row class="form-row">
        <Col span="12">
        <FormItem label="固定电话" prop="tel">
          <Input v-model="formValidate.tel" placeholder="请输入固定电话" class="ivu-input-fix"></Input>
        </FormItem>
        </Col>
        <Col span="12">
        <FormItem label="邮箱地址" prop="email">
          <Input v-model="formValidate.email" placeholder="请输入邮箱地址" class="ivu-input-fix"></Input>
        </FormItem>
        </Col>
      </Row>
      <Row class="form-row">
        <Col span="24">
        <FormItem label="留言" prop="remark">
          <Input v-model="formValidate.remark" placeholder="请输入留言" type="textarea" :rows="4" class="ivu-input-fix"></Input>
        </FormItem>
        </Col>
      </Row>
    </Form>
    <template #footer>
      <Button @click="cancel">取消</Button>
      <Button type="error" @click="quoteCommit">立即报价</Button>
    </template>
  </Modal>
</template>

<script>
import { toRaw } from 'vue'
export default {
  name: 'QueteModal',
  props: ['showModal', 'id'],
  data () {
    return {
      localShowModal: false,
      formValidate: {
        address: null,
        companyName: null,
        price: null,
        phone: null,
        tel: null,
        email: null,
        remark: null,
      },
      ruleValidate: {
        address: [
          { required: true, message: '详细地址不能为空', trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '供应商单位不能为空', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '报价不能为空', trigger: 'blur', type: 'number' }
        ],
        phone: [
          { required: true, message: '手机号码不能为空', trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '固定电话不能为空', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '邮箱地址不能为空', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '备注不能为空', trigger: 'blur' }
        ],
      },
    }
  },
  watch: {
    showModal (val) {
      this.localShowModal = val
    }
  },
  mounted () {
    this.localShowModal = this.showModal
  },
  methods: {
    quoteCommit () {
      this.$refs.formValidate.validate((valid) => {
        // 验证表格和表单其他必填项
        if (!valid) {
          this.$Message.error('请填写必填项!')
          return
        }
        const params = toRaw(this.formValidate)
        params.purchasePreOrderGoodsId = this.id
        this.$store.dispatch('publish/SaveQuotation', params).then(res => {
          if (res.returnCode === '1') {
            this.$Message.success('提交成功')
            this.$emit('cancel', false)
          }
        })
      })
    },
    cancel () {
      this.$emit('cancel', false)
    }
  }
}
</script>

<style lang="less" scoped>
.quete-modal {
  .ivu-input-fix {
    padding-right: 10px;

    /deep/ .ivu-input {
      border: 1px solid #dcdee2 !important;
      padding: 4px 7px !important;
    }
  }

  .ivu-btn-error {
    background-color: #d12b23;
  }

  .form-row {
    padding-left: 10px;
  }
}
</style>
