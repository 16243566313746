<template>
  <div>
    <h2>最新求购</h2>
    <div class="main-content">
      <div class="search">
        <div class="search-input">
          <label for="">分类</label>
          <Cascader
            placeholder="请选择分类"
            :data="categoryOptions"
            :transfer="true"
            trigger="hover"
            clearable
            @on-change="tableSelectChange"
          ></Cascader>
        </div>
        <div class="search-input">
          <label for="">商品名称</label>
          <Input
            v-model="params.productName"
            placeholder="请输入商品名称"
            clearable
            @on-clear="getPreOrderGoodsList"
            @keyup.enter="getPreOrderGoodsList"
          />
        </div>
        <div class="search-input">
          <label for="">规格型号</label>
          <Input
            v-model="params.formate"
            placeholder="请输入规格型号"
            clearable
            @on-clear="getPreOrderGoodsList"
            @keyup.enter="getPreOrderGoodsList"
          />
        </div>
        <div class="search-input">
          <label for="">发布企业</label>
          <Input
            v-model="params.companyName"
            placeholder="请输入发布企业"
            clearable
            @on-clear="getPreOrderGoodsList"
            @keyup.enter="getPreOrderGoodsList"
          />
        </div>
        <Button type="error" @click="getPreOrderGoodsList">查询</Button>
      </div>

      <NeedListSort @sortChange="sortChange" />

      <div class="table">
        <Table :columns="columns" :data="dataList">
          <template #price="{ row }">
            <span style="color: #de6a64">{{ row.price }}</span>
          </template>
          <template #status="{ row }">
            <span>
              {{ statusList[row.status] }}(
              <span style="color: #de6a64">{{ row.qoCount }}</span>
              )
            </span>
          </template>
          <template #action="{ row, index }">
            <Row justify="start">
              <Button
                type="text"
                size="small"
                style="color: #80beff; background: none; box-shadow: none"
                @click="toDetail({ row, index })"
              >
                查看详情
              </Button>
              <Button
                type="text"
                size="small"
                style="color: #80beff; background: none; box-shadow: none"
                @click="showQuotedPrice({ row, index })"
              >
                报价
              </Button>
            </Row>
          </template>
        </Table>
        <Row justify="end" class="table-page">
          <Page
            v-model="pageInfo.pageIndex"
            :total="pageInfo.total"
            :page-size="pageInfo.pageSize"
            @on-change="pageChange"
            @on-prev="pageChange"
            @on-next="pageChange"
          />
        </Row>
      </div>
    </div>
    <QueteModal
      v-if="showQuote"
      :id="id"
      :showModal="showQuote"
      @cancel="showQuote = false"
    ></QueteModal>
  </div>
</template>

<script>
import NeedListSort from './components/NeedListSort'
import QueteModal from './components/QueteModal'
import { initialNeedsListTable } from './config'
// import json from './mock.json'
export default {
  name: 'NeedsList',
  data () {
    return {
      categoryTreeList: [],
      categoryOptions: [],
      pageInfo: {
        pageIndex: 1,
        total: 0,
        pageSize: 10,
        sort: 'desc',
      },
      dataList: [],
      showQuote: false, // 显示报价弹框
      statusList: {
        // 所有订单状态
        1: '待报价',
        2: '待确认',
        3: '已取消',
        4: '撮合成功',
      },
      params: {
        page: 1,
        rows: 10,
        productName: '',
        formate: '',
        companyName: '',
        categoryId: '',
				subCategoryId: '',
        beginDate: '',
        sidx: 'create_date',
        sord: 'desc',
      },
    }
  },
  setup () {
    const columns = initialNeedsListTable()
    return {
      columns,
    }
  },
  created () {
    this.getCategoryOptions()
    this.getPreOrderGoodsList()
    this.bankCall() // 银行支付回调
  },
  methods: {
    bankCall () {
      if (JSON.stringify(this.$route.query) === '{}') {
        this.$store
          .dispatch('PaymentUpdatePayStatus', this.$route.query)
          .then((response) => {
          })
          .catch(() => {})
      }
    },
    /* 获取分类 */
    getCategoryOptions () {
      this.$store
        .dispatch('CategoryTree')
        .then((response) => {
          this.categoryTreeList = response.result
          response.result.forEach((item) => {
            const children = []
            item.subCategoryList.forEach((i) => {
              children.push({
                value: i.categoryId,
                label: i.categoryName,
              })
            })
            // 获取所有分类信息
            this.categoryOptions.push({
              value: item.deposit.categoryId,
              label: item.deposit.categoryName,
              children,
            })
          })
        })
        .catch((e) => {

        })
    },
    getPreOrderGoodsList () {
      this.$store
        .dispatch('publish/GetPreOrderGoodsList', this.params)
        .then((res) => {
          if (res.returnCode === '1') {
            this.dataList = res.result.rows
            this.pageInfo.total = res.result.records
            // this.dataList = json.dataList
          } else {
            this.dataList = []
            this.pageInfo.total = 0
          }
        })
    },
    toDetail ({ row }) {
      this.$router.push({ name: 'needslist_detail', params: { id: row.id }})
    },
    /* 显示报价弹窗 */
    showQuotedPrice ({ row }) {
      this.showQuote = true
      this.id = row.id
    },

    tableSelectChange (val) {
      this.params.subCategoryId = val[1]
      this.getPreOrderGoodsList()
    },
    quoteCommit () {},
    pageChange (page) {
      this.pageInfo.pageIndex = page
      this.params.page = page
      this.getPreOrderGoodsList()
    },
    sortChange (val) {
      this.params.sidx = val
      this.getPreOrderGoodsList()
    },
  },
  components: {
    NeedListSort,
    QueteModal,
  },
}
</script>
<style lang="less" scoped>
.main-content {
  padding-bottom: 30px;

  .search {
    display: flex;
    margin: 30px 0;

    .search-input {
      margin-right: 20px;
      display: flex;
      align-items: center;

      label {
        width: 100px;
        text-align: right;
        padding-right: 10px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
